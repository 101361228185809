import React, { useMemo } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import semPowerImg from '../../../../../assets/images/activate_sem.svg';
import semPowerCycleGuideImg from '../../../../../assets/images/sem_power_cycle_guide.jpg';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';

export function MeterPowerUpInstructions() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();

  const backURL = useMemo(() => {
    const deviceId = search.get('deviceId');
    // If we have a deviceId, we are coming from the meter details page
    return deviceId ? `../../../${deviceId}/view?direction=back` : `../start?direction=back`;
  }, [search]);

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" px={3} my={1} textAlign="center">
        <Image w={'70%'} src={semPowerImg} alt="energy monitor power icon" />

        <Heading mt={2}>{t('Set Up Hardware.power cycle sem title')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.power cycle sem text')}</Text>
        <Image borderRadius="5%" w={'70%'} my={2} src={semPowerCycleGuideImg} alt="energy monitor power cycle guide" />

        <ArcButton
          data-testid={'continue-btn'}
          onClick={() => {
            const params = new URLSearchParams(search);
            params.set('direction', 'forward');
            navigate(`../connect_instructions?${params.toString()}`);
          }}
          mt={5}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
