import { useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';

import { AylaLiveDataSummary } from 'clipsal-cortex-types/src/api/api-ayla-live-data';
import { GridStatus, InverterStatus, SaturnLiveDataSummary } from 'clipsal-cortex-types/src/api/api-saturn-live-data';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { baseApi } from '../../../app/services/baseApi';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { REGION_SHORTHAND } from '../../../region-type';
import demoLiveData from '../../demo-login/stubs/sense-live-data.json';
import { MANUFACTURER_ID_SATURN, MANUFACTURER_ID_SEM } from '../../devices/devices-helper';
import { selectInverters, selectMeters, selectSite } from '../siteSlice';
import { CombinedLiveData, LiveDataMessage, LiveDataResult, LiveSenseData } from './types';

const WEBSOCKET_ENDPOINT = import.meta.env[
  `VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_WEBSOCKET_API_URL`
] as string;

// 0.1 kW above or below 0 is usually just noise
const POWER_THRESHOLD_KW = 0.1;

let numTimesRetried = 0;
const MAX_WEBSOCKET_RETRY_ATTEMPTS = 1;
const WEBSOCKET_RETRY_TIMEOUT_MS = 5_000;

// An identifier is required to ensure a new cache entry is created each time an attempt to retry connection to the
// meter (via the websocket) occurs. This is a caveat of how RTKQ manages cache keys, and really only comes into account
// under circumstances where the connection fails and must be re-attempted.
type MeterLiveDataQueryParams = { siteId: number; queryIdentifier: number };
let queryIdentifier = 0; // Tracks the "attempt number". Used to parameterize the live data query
/**
 * NOTE: This is the only way to invalidate meter query data, as invalidating the tags does _NOT_ re-fetch data
 * if the cache key has any data in it already.
 */
export function invalidateMeterQueryData() {
  queryIdentifier++;
}

export const liveDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInverterLiveData: build.query<SaturnLiveDataSummary, number>({
      providesTags: ['InverterLiveData'],
      query: (siteId) => `/v1/sites/${siteId}/saturn_live_data_summary`,
    }),
    getAylaLiveData: build.query<AylaLiveDataSummary[], number>({
      providesTags: ['AylaLiveData'],
      query: (siteId) => `/v1/sites/${siteId}/ayla_live_data_summary`,
    }),
    /**
     * This is a special query that uses a websocket to get live data. A breakdown of what happens:
     * 1. A component subscribes to this query by calling the hook
     * 2. The queryFn is called, which returns a dummy value to the cache
     * 3. The `onCacheEntry` callback is called, which creates a websocket connection and updates the cache
     *    with new values coming from the live data stream. This stream updates once every 500ms, and lasts for 5
     *    minutes. Once this time has elapsed, and the connection is still active, the server returns a `reconnect`
     *    type message -- if we're still connected, we just re-subscribe to the site.
     *
     * In the event of an unknown message structure (generally an error), we don't do anything, as there are multiple
     * no-op errors which come from the websocket (e.g. API gateway function timeout, which doesn't actually affect
     * the data stream).
     */
    getMeterLiveData: build.query<
      LiveSenseData & {
        isLoaded: boolean;
        isError: boolean;
        error: string | null;
      },
      MeterLiveDataQueryParams
    >({
      providesTags: ['MeterLiveData'],
      queryFn: () => {
        if (IS_DEMO_LOGIN) {
          return {
            data: {
              ...(demoLiveData.payload as any),
              isLoaded: true,
              isError: false,
              error: null,
            },
          };
        }

        // We don't actually use the siteId param, it's just for caching.
        return {
          data: {
            ac_load_net: 0,
            consumption: 0,
            devices: [],
            switches: [],
            epoch: 0,
            solar: 0,
            hybrid_inverter: 0,
            isLoaded: false,
            isError: false,
            error: null,
          },
        };
      },
      keepUnusedDataFor: Infinity,
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        if (IS_DEMO_LOGIN) {
          updateCachedData(() => ({
            ...(demoLiveData.payload as any),
            isLoaded: true,
            isError: false,
            error: null,
          }));
          return;
        }
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const ws = new WebSocket(`${WEBSOCKET_ENDPOINT}/?token=${token}`);

        /*
          The following timeout is used to keep track of WS messages being received.
          Once the timeout elapses - an error is reported to the cache.

          This helps handle the following scenarios:
          1. The WS stops sending data unexpectedly (without issuing a `close` message).
             If it resumes sending data, the timeout is reset.

          2. The user's network connection disconnects then reconnects.
             If the network reconnects, the timeout is reset.
        */
        let websocketMessageTimeout: NodeJS.Timeout;

        function resetWebsocketMessageTimer() {
          if (websocketMessageTimeout) {
            clearTimeout(websocketMessageTimeout);
          }

          websocketMessageTimeout = setTimeout(async () => {
            // Once maximum retries is exceeded, show an error message to the user and let them manually retry.
            if (numTimesRetried <= MAX_WEBSOCKET_RETRY_ATTEMPTS) {
              invalidateMeterQueryData();
              numTimesRetried++;
            } else {
              numTimesRetried = 0;
              updateCachedData((draft) => ({
                ...draft,
                isLoaded: true,
                isError: true,
                error: 'WEBSOCKET_ERROR',
              }));
            }
          }, WEBSOCKET_RETRY_TIMEOUT_MS);
        }

        try {
          await new Promise<void>((res, rej) => {
            ws.onopen = () => {
              res();
            };

            ws.onerror = () => {
              rej();
            };
          });
        } catch {
          updateCachedData((draft) => ({
            ...draft,
            isLoaded: true,
            isError: true,
            error: 'WEBSOCKET_CONNECT_ERROR',
          }));
          return;
        }

        // Subscribe to the site
        ws.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: arg.siteId }));

        const handleDisconnect = () => {
          updateCachedData((draft) => ({
            ...draft,
            isLoaded: true,
            isError: true,
            error: 'NETWORK_ERROR',
          }));
        };

        const handleReconnect = () => {
          resetWebsocketMessageTimer();
        };

        window.addEventListener('offline', handleDisconnect);
        window.addEventListener('online', handleReconnect);

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          const handleWebSocketMessage = (event: MessageEvent) => {
            try {
              resetWebsocketMessageTimer();
              const data: LiveDataMessage = JSON.parse(event.data);

              if (data?.type == 'realtime_update') {
                numTimesRetried = 0; // Reset number of retry attempts if we got data from the WS
                updateCachedData(() => ({
                  ...data.payload,
                  isLoaded: true,
                  isError: false,
                  error: null,
                }));
              } else if (data?.type == 'reconnect') {
                ws.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: arg.siteId }));
              } else if (data?.type == 'error') {
                updateCachedData((draft) => ({
                  ...draft,
                  isLoaded: true,
                  isError: true,
                  error: 'WEBSOCKET_ERROR',
                }));
              } else if (data?.type == 'close') {
                ws.close();
                // No-op
              } else if (data?.type == 'open') {
                // No-op
              } else if (data?.type == 'info') {
                // No-op
              } else {
                console.log('UNKNOWN MESSAGE: ', data);
              }
            } catch (error) {
              ws.close();
              updateCachedData((draft) => ({
                ...draft,
                isLoaded: true,
                isError: true,
                error: 'WEBSOCKET_ERROR',
              }));
            }
          };
          ws.addEventListener('message', handleWebSocketMessage);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.send(JSON.stringify({ action: 'unsubscribeSiteLiveData', clipsal_solar_id: arg.siteId }));
        ws.close();
      },
    }),
  }),
});

export const { useGetMeterLiveDataQuery, useGetInverterLiveDataQuery, useGetAylaLiveDataQuery } = liveDataApi;

/**
 * A hacky solution to combining live data from two sources.
 * If a site has an inverter, we call the Saturn API via polling.
 * If a site has a meter, we use a websocket to create an incoming data stream.
 * If a site has both, we prioritize meter data over inverter data for grid/solar/consumption (obviously not battery).
 *
 * Ideally we're going to move this logic to the back-end.
 *
 * @returns A query result-like object with the combined data and other metadata about the response/s.
 */
export function useLiveData(skip = false): LiveDataResult {
  const { site_id: siteId } = useSelector(selectSite);
  const isAppVisible = useAppVisibility() || IS_DEMO_LOGIN; // No need to skip API calls in demo mode
  const siteMeters = useSelector(selectMeters);
  const siteInverters = useSelector(selectInverters);
  const siteHasSaturnInverter = siteInverters.some((inverter) =>
    [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)
  );
  const siteHasSenseMeter = siteMeters.some((meter) => [MANUFACTURER_ID_SEM].includes(meter.manufacturer_id));
  const { data: liveMeterData } = useGetMeterLiveDataQuery(
    { siteId, queryIdentifier },
    {
      // Skip calling the meter setup "endpoint" (i.e. connecting to the websocket) when either:
      // 1. The site does not have a meter
      // 2. The consumer of this hook specifies that the call should be skipped
      // 3. The app is not visible
      skip: !siteHasSenseMeter || skip || !isAppVisible,
    }
  );
  const {
    data: liveInverterData,
    isLoading: isInverterDataLoading,
    isError: isInverterDataError,
    isFetching: isInverterDataFetching,
    error: inverterError,
  } = useGetInverterLiveDataQuery(siteId, {
    pollingInterval: 5000,
    skip: !siteHasSaturnInverter || skip || !isAppVisible,
  });

  const isInverterStatusError =
    liveInverterData?.inverter_status !== InverterStatus.NORMAL &&
    liveInverterData?.inverter_status !== InverterStatus.UNDEFINED;

  const liveData = useMemo(() => {
    const dataBase: CombinedLiveData = {
      last_updated: '',
      appliances: [],
      switches: [],
      solar: 0,
      grid: 0,
      consumption: 0,
      self_powered_fraction: 0,
      blackout: false,
      hybrid_inverter: 0,
      grid_status: GridStatus.UNDEFINED, // Only used by Saturn data, no way to get this from Sense yet
      inverter_status: InverterStatus.UNDEFINED,
    };
    let dataMeter: CombinedLiveData = { ...dataBase };
    let dataInverter: CombinedLiveData = { ...dataBase };

    // Lowest priority is Sense meter data, except for consumption and grid data.
    // Many of the values here are overwritten if a site has a Saturn inverter.
    if (siteHasSenseMeter && !liveMeterData?.isError && liveMeterData?.isLoaded && liveMeterData) {
      // Note: Sense data is in W, so convert to kW
      dataMeter = {
        ...dataMeter,
        appliances: liveMeterData.devices.map((device, index) => {
          return {
            assignment: device.assignment,
            power: (device.w ?? 0) / 1000,
            display_name: device.name ?? `Device ${index + 1}`,
            appliance_id: device.appliance_id,
            control_device_id: null,
          };
        }),
        switches: liveMeterData.switches.map((senseSwitch) => {
          return {
            id: senseSwitch.switch_id,
            state: senseSwitch.state,
            status: senseSwitch.status,
            assignment: senseSwitch.assignment,
            power: (senseSwitch.w ?? 0) / 1000,
            display_name: senseSwitch.name,
            appliance_id: senseSwitch.appliance_id,
            control_device_id: null,
          };
        }),
        // Ensures solar can't be negative (mis-configured site)
        solar: Math.max(liveMeterData.solar / 1000, 0),
        grid: liveMeterData.ac_load_net / 1000,
        consumption: liveMeterData.consumption / 1000,
        hybrid_inverter: (liveMeterData.hybrid_inverter ?? 0) / 1000,
        last_updated: new Date(liveMeterData.epoch * 1000).toISOString(),
      };
    }

    // Highest priority is inverter data -- if a site has an inverter, we over-write _everything_ from Sense, except
    // for appliances and switches.
    if (siteHasSaturnInverter && !isInverterDataLoading && !isInverterDataError && liveInverterData) {
      const isBatteryBelowThreshold = Math.abs(liveInverterData.battery ?? 0) < POWER_THRESHOLD_KW;
      const isSolarBellowThreshold = Math.abs(liveInverterData.solar ?? 0) < POWER_THRESHOLD_KW;

      dataInverter = {
        ...dataMeter,
        ...('battery' in liveInverterData
          ? {
              battery: isBatteryBelowThreshold ? 0 : liveInverterData.battery,
              battery_capacity_wh: liveInverterData.battery_capacity_wh,
              battery_soc_fraction: liveInverterData.battery_soc_fraction,
              battery_duration_sec: liveInverterData.battery_duration_sec,
            }
          : {}),
        solar: isSolarBellowThreshold ? 0 : liveInverterData.solar,
        grid: liveInverterData.grid,
        consumption: liveInverterData.consumption,
        last_updated: liveInverterData.last_updated,
        grid_status: liveInverterData.grid_status,
      };
      return { ...dataInverter };
    }

    return { ...dataMeter };
  }, [
    liveInverterData,
    liveMeterData,
    siteHasSaturnInverter,
    siteHasSenseMeter,
    isInverterDataLoading,
    isInverterDataError,
  ]);

  return {
    data: liveData,
    isLoading: (siteHasSaturnInverter && isInverterDataLoading) || (siteHasSenseMeter && !liveMeterData?.isLoaded),
    isInverterError: siteHasSaturnInverter && (isInverterDataError || isInverterStatusError),
    isMeterError: siteHasSenseMeter && !!liveMeterData?.isError,
    isMeterDataLoading: siteHasSenseMeter && !liveMeterData?.isLoaded,
    isInverterDataLoading: siteHasSaturnInverter && isInverterDataLoading,
    isFetching: siteHasSaturnInverter && isInverterDataFetching,
    error: (inverterError?.message ?? null) || (liveMeterData?.error ?? null),
  };
}
