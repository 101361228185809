import React, { useMemo, useState } from 'react';
import { ScanResult } from '@capacitor-community/barcode-scanner';
import { Wifi } from '@capacitor-community/wifi/src';
import { Network } from '@capacitor/network';
import { Center, Heading, Image, Text, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import wifiConnectionFailed from '../../../../../assets/images/sem_commissioning_unsuccessful.svg';
import semQrCodeImg from '../../../../../assets/images/sem_qr_code_scan.svg';
import wifiConnectingImg from '../../../../../assets/images/wifi_connecting.svg';
import { get } from '../../../../../common/api/api-helpers';
import ScanBarcodeButton from '../../../../../common/components/ScanBarcodeButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../../common/components/TopNavProgressLoader';
import { selectSite } from '../../../../site/siteSlice';
import { extractWifiCredentials } from '../helpers';
import { WirelessAccessPointConnectionStatus } from './types';

/* istanbul ignore next -- @preserve */
export function MeterScanBarcode() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true });
  const [connectionStatus, setConnectionStatus] = useState<WirelessAccessPointConnectionStatus>('NONE');
  const { t } = useTranslation();

  async function fetchAccountId(serialNum: string) {
    try {
      return await get<{ account_id: number }>(`/v1/sites/${site.site_id}/sense_account_id/${serialNum}`);
    } catch (e) {
      const error = e as AxiosError;
      console.error(e);

      if (error.response?.status === 404) {
        // The SEM may not yet be in our fleet, so a 404 can happen -- let the user proceed with an empty account id,
        // since in this case there should be no requirement for digest auth.
        return {
          account_id: null,
        };
      } else throw e;
    }
  }

  async function handleScanBarcode(scanResult: ScanResult) {
    if (scanResult.content) {
      setConnectionStatus('CONNECTING');

      // Before proceeding with SEM connections, we need to ensure the user is connected to the internet.
      // They could still be on the SEM wifi network if they retry at this point. Due to iOS restrictions,
      // we are unable to manually disconnect them from the SEM wifi network.
      const { connected: isConnectedToInternet } = await Network.getStatus();
      if (!isConnectedToInternet) {
        setConnectionStatus('FAILED');
        toast({
          title: t('Common.not connected to internet'),
          description: `${t('Set Up Hardware.make sure you are connected')} ${t(
            'Set Up Hardware.you may need to reconnect'
          )}`,
          status: 'error',
        });
        return;
      }

      try {
        const { ssid, password } = extractWifiCredentials(scanResult.content);

        // The expected SSID structure is "Schneider Monitor <serial>"
        const serialNum = ssid.split(' ')[2];
        // Before connecting to the Wi-Fi, we need to fetch the accountId for the site.
        // It's expected the user will have an internet connection at this point.
        const accountIdData = await fetchAccountId(serialNum);
        const { account_id: accountId } = accountIdData;

        try {
          await Wifi.connect({ ssid, password, isHiddenSsid: false });
          try {
            // @TODO: uncomment when self-commissioning API bug has been resolved by Sense
            // // Here we wait a bit after connecting to the Wi-Fi before moving on to ensure we are connected -
            // // - before immediately making any network requests
            // await timeout(2000);
            // // Once the connection is successful, check the connection.
            // const status = await get<MonitorStatus>(`${BASE_IPV4_ADDRESS}/api/status`);
            // // If the status is failing, inform the user and do not allow them to proceed.
            // if ('w' in status && !!status.w && !status.w.R) {
            //   setConnectionStatus('STATUS_CHECK_FAILED');
            // } else {

            // This ensures when coming from hardware view page, we can go back to the same page when going back
            search.set('connectedSSID', ssid);
            search.set('accountId', accountId?.toString() ?? '');
            navigate(`../connection_tests?${search.toString()}`);
            // }
          } catch (e) {
            Sentry.captureException(e);
            // Note: This can fail for various reasons, but generally indicates an existing account lock.
            console.error(e);
            setConnectionStatus('COMMISSIONING_TEST_CONNECTION_FAILED');
            toast({
              title: t('Set Up Hardware.error running commissioning tests'),
              description: t('Common.please try again'),
              status: 'error',
            });
          }
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
          setConnectionStatus('FAILED');
          toast({
            title: t('Set Up Hardware.error connecting to wifi'),
            description: t('Common.please try again'),
            status: 'error',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setConnectionStatus('INVALID_QR_CODE');
        toast({
          title: t('Common.invalid qr code'),
          description: t('Common.please try again'),
          status: 'error',
        });
      }
    }
  }

  let contents = (
    <>
      <Image w={'70%'} my={4} src={semQrCodeImg} alt="SEM sketch" />
      <Heading mt={2}>{t('Set Up Hardware.allow camera')}</Heading>
      <Text mt={1}>{t('Set Up Hardware.activate sem text')}</Text>

      <ScanBarcodeButton
        data-testid={'meter-open-camera'}
        onScan={handleScanBarcode}
        buttonText={t('Common.open camera')}
        onScanError={() => setConnectionStatus('NONE')}
        mt={8}
        w={'80%'}
      />
    </>
  );

  if (connectionStatus === 'CONNECTING') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Image w={'70%'} src={wifiConnectingImg} alt="Connecting to meter" />
        <Heading mt={2}>{t('Set Up Hardware.searching wifi')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.this allows the device', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
      </>
    );
  }

  if (connectionStatus === 'FAILED') {
    contents = (
      <>
        <Image w={'70%'} my={4} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to connect')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.unable to connect to device wap', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
        <Text mt={1}>{t('Common.please try again')}</Text>
        <Text mt={1}>{t('Common.if this persists contact support')}</Text>
        <ScanBarcodeButton
          onScan={handleScanBarcode}
          onScanError={() => setConnectionStatus('NONE')}
          mt={8}
          w={'80%'}
          buttonText={t('Common.try again')}
        />
      </>
    );
  }

  if (connectionStatus === 'INVALID_QR_CODE') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="QR Code scan failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to validate qr')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.ensure wifi enabled')}</Text>
        <Text my={1}>{t('Common.please try again')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  if (connectionStatus === 'STATUS_CHECK_FAILED') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.device setup problem')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.issue with hardware config')}</Text>
      </>
    );
  }

  if (connectionStatus === 'COMMISSIONING_TEST_CONNECTION_FAILED') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.error running commissioning tests')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.please try power cycling')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    search.set('direction', 'back');
    return search.toString();
  }, [search]);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../connect_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        {contents}
      </Center>
    </SlidingAnimationPageBase>
  );
}
