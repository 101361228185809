import './live-energy-flow.scss';

import React, { RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useLiveData } from '../site/live-data/liveDataApi';
import { selectBatteries, selectEvChargers, selectInverters } from '../site/siteSlice';
import {
  BatteryIcon,
  EVGarage,
  HouseFoundation,
  HouseWithPowerPoleOutline,
  Roof,
  SolarPanel,
  // GeneratorIcon,
} from './animation-icons';
import { EnergyFlowGroupIndex, EnergyFlowPathIndex, useEnergyAnimation } from './animation-renderers';
import WeatherAnimation from './WeatherAnimation';

interface Props {
  isParentLoaded: boolean;
}

// Reference indices for the SVG circle elements that move along paths in the animation
enum CircleIndex {
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  Circle5,
}

export default function LiveEnergyAnimation({ isParentLoaded }: Props) {
  const { data: liveDataSummary } = useLiveData();
  const { battery_soc_fraction: socFraction = 0 } = liveDataSummary;

  const siteHasBattery = useSelector(selectBatteries).length > 0;
  const siteHasEV = useSelector(selectEvChargers).length > 0;
  const siteHasInverter = useSelector(selectInverters).length > 0;

  const pathRefs = useRef<RefObject<SVGPathElement>[]>(Array.from(Array(12)).map(() => React.createRef()));
  const groupRefs = useRef<RefObject<SVGGElement>[]>(Array.from(Array(7)).map(() => React.createRef()));
  const circleRefs = useRef<RefObject<SVGCircleElement>[]>(Array.from(Array(5)).map(() => React.createRef()));
  const sunToSolarPanelLineRef = useRef<SVGPathElement>(null);

  useEnergyAnimation(pathRefs.current, groupRefs.current, circleRefs.current, isParentLoaded, sunToSolarPanelLineRef);

  return (
    <svg width="349" height="270" viewBox="0 0 349 270" fill="none">
      <HouseWithPowerPoleOutline
        doorAndWindowsRef={groupRefs.current[EnergyFlowGroupIndex.DoorAndWindows]}
        powerPoleRef={groupRefs.current[EnergyFlowGroupIndex.PowerPole]}
        housePowerBoxRef={groupRefs.current[EnergyFlowGroupIndex.HousePowerBox]}
      />
      {/* Set up the house based on configured devices */}
      {siteHasInverter ? <SolarPanel solarPanelRef={groupRefs.current[EnergyFlowGroupIndex.SolarPanel]} /> : <Roof />}
      {siteHasEV ? <EVGarage EVRef={groupRefs.current[EnergyFlowGroupIndex.EV]} /> : <HouseFoundation />}
      {siteHasBattery && (
        <BatteryIcon
          percentageFilled={Math.round(socFraction * 100)}
          batteryRef={groupRefs.current[EnergyFlowGroupIndex.Battery]}
        />
      )}
      <path ref={pathRefs.current[EnergyFlowPathIndex.SolarHouse]} d={'M224.8,152 V203 H154'} />
      <path ref={pathRefs.current[EnergyFlowPathIndex.SolarBattery]} d={'M224.8,152 V203 H268.9 V215'} />
      <path ref={pathRefs.current[EnergyFlowPathIndex.SolarEV]} d={'M224.8,152 V203 H160 V248 H177'} />
      <path
        ref={pathRefs.current[EnergyFlowPathIndex.SolarGrid]}
        d={'M224.8,152 V203 H283.75 V179 C317,182 326,179 332,176 L332,171'}
      />
      <path
        ref={pathRefs.current[EnergyFlowPathIndex.GridHouse]}
        d={'M332,171 L332,176 C326,179 317,180 308,180 C301,180.5 283.75,179.69 283.75,178 L283.75,203 L154,203'}
      />
      <path
        ref={pathRefs.current[EnergyFlowPathIndex.GridEV]}
        d={'M332,171 L332,176 C326,179 317,180 308.221,180 C301,180 284,180 284,178 V203 H160 V248 H177'}
      />
      <path
        ref={pathRefs.current[EnergyFlowPathIndex.GridBattery]}
        d={'M332,171 L332,176 C326,179 317,180 308.221,180 C301,180 284,180 284,178 V203 H269 V215'}
      />
      <path
        ref={pathRefs.current[EnergyFlowPathIndex.BatteryGrid]}
        d={'M269,214 V203 H283.75 V179 C317,182 326,179 332,176 L332,171'}
      />
      <path ref={pathRefs.current[EnergyFlowPathIndex.BatteryHouse]} d={'M269,214 V203 H154'} />
      <path ref={pathRefs.current[EnergyFlowPathIndex.BatteryEV]} d={'M269,214 V203 H159.75 V248 H177'} />
      <path ref={pathRefs.current[EnergyFlowPathIndex.GeneratorHouse]} d={'M293,240 H283 V203 H154'} />
      <path ref={pathRefs.current[EnergyFlowPathIndex.GeneratorEV]} d={'M293,240 H283 V203 H160 V247 H180'} />

      <WeatherAnimation sunToSolarPanelLineRef={sunToSolarPanelLineRef} />
      <circle
        ref={circleRefs.current[CircleIndex.Circle1]}
        strokeWidth="2px"
        r="3px"
        data-testid="circle1-icon"
        display="none"
      />
      <circle
        ref={circleRefs.current[CircleIndex.Circle2]}
        strokeWidth="2px"
        r="3px"
        data-testid="circle2-icon"
        display="none"
      />
      <circle
        ref={circleRefs.current[CircleIndex.Circle3]}
        strokeWidth="4px"
        r="5px"
        fill="#000000"
        data-testid="circle3-icon"
        display="none"
      />
      <circle
        ref={circleRefs.current[CircleIndex.Circle4]}
        strokeWidth="2px"
        r="3px"
        data-testid="circle4-icon"
        display="none"
      />
      <circle
        ref={circleRefs.current[CircleIndex.Circle5]}
        strokeWidth="2px"
        r="3px"
        data-testid="circle5-icon"
        display="none"
      />
    </svg>
  );
}
